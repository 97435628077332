/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useLocation } from "react-router";

import "./lmt.css";

const LmtPage = () => {
  const widget = React.useRef(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  React.useEffect(() => {
    const initWidget = () => {
      if (window.SIR) {
        window.SIR("addWidget", widget.current, "match.lmtPlus", {
          layout: "single",
          onlyScoreboard: false,
          showOdds: true,
          scoreboard: params.get("scoreboard") || "disable", // 显示头 compact/disable
          detailedScoreboard: params.get("detailed") || "disable", // 显示比分 enable/disable
          matchId: params.get("id"),
        });
      }
    };
    if (!window.SIR) {
      window.SIR = (...params) => {
        if (!window.SIR.q) {
          window.SIR.q = [];
        }
        window.SIR.q.push(params);
      };

      window.SIR.l = Date.now();
      window.SIR.o = {
        theme: false,
        language: params.get("locale") || "zh",
      };

      const el = document.createElement("script");
      el.async = 1;
      // TODO:2022.01.05改
      el.src =
        "https://ws-cdn001.akamaized.net/713ad42ed654599df32c28e1f95b871b/widgetloader";
      el.setAttribute("n", "SIR");
      el.addEventListener("load", initWidget);

      document.querySelector("head").appendChild(el);
    } else {
      initWidget();
    }
  }, [params]);
  return <div ref={widget} className="lmt-root" />;
};

export default LmtPage;
